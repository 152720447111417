import React from 'react'
import style from './actionButtonStyle.module.scss'
import FireIcon from '../../../Resources/Predict/icons/fire.svg';

interface IProps {
    title: string;
    titleIcon?: string;
    secondTitle?: string;
    secondTitleIcon?: string;
    subTitle?: string | null;
    onClick?: () => void;
    disabled?: boolean;
    selected?: boolean;
    withoutIcon?: boolean;
    size?: "normal" | "small";
    isHot?: boolean;
}

export function DownButton(props: IProps) {
    const size = props.size ?? "normal";

    function handleClick() {
        if (props.disabled == true || !props.onClick) return;

        props.onClick();
    }

    return (
        <div className={`${style.container} ${style.red} ${props.disabled == true ? style.disabled : ''} ${props.selected == true ? style.selected : ''} ${size == 'small' ? style.small : ''}`} onClick={handleClick}>
            <div>
                <div>
                    {props.titleIcon != null && <img src={props.titleIcon} />}
                    <span className={`${style.title} ${style.red}`}>{props.title}</span>
                </div>
                {props.secondTitle &&
                    <div>
                        {props.secondTitleIcon != null && <img src={props.secondTitleIcon} />}
                        <span className={`${style.title} ${style.red}`}>{props.secondTitle}</span>
                    </div>
                }
            </div>
            {props.subTitle && <span className={`${style.subTitle} ${style.red}`}>{props.subTitle}</span>}
            {
                props.withoutIcon != true &&
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.13397 10.5C5.51887 11.1667 6.48113 11.1667 6.86603 10.5L10.3301 4.5C10.715 3.83333 10.2339 3 9.4641 3H2.5359C1.7661 3 1.28497 3.83333 1.66987 4.5L5.13397 10.5Z" fill={props.disabled ? "#cacaca" : "#F54570"} />
                </svg>
            }
            {props.isHot == true && <img src={FireIcon} />}
        </div>
    )
}
