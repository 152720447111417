import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import BackIcon from '../../../Resources/Predict/icons/back.svg';
import style from './profileStyle.module.scss';
import GameContext from '../../../Context/GameContext';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActiveGame } from '../../../Domain/Predict/acitveGame';
import './sliderStyle.css'
import { initSwipeBehavior } from '@telegram-apps/sdk-react';

export default function ProfileHistoryBlock() {
    const { token, score, userId } = useContext(GameContext);
    const navigate = useNavigate();

    const [activeGames, setActiveGames] = useState<ActiveGame[]>([]);
    const [currentDateTimeUtc, setCurrentDateTimeUtc] = useState<Date | null>(null);

    const { t } = useTranslation();
    const [swipeBehavior] = initSwipeBehavior();
    const location = useLocation();

    useEffect(() => {
        if (swipeBehavior.supports("disableVerticalSwipe")) swipeBehavior.disableVerticalSwipe();

        return () => {
            if (swipeBehavior.supports("enableVerticalSwipe")) swipeBehavior.enableVerticalSwipe();
        }
    }, [])

    const currentLocation = location.pathname;

    return (
        <div className={style.profileHistoryBlock}>
            <div className={style.profileHistoryBlock_header}>
                <div className={style.profileHistoryBlock_title}>
                    <div className={style.profileHistoryBlock_header_back} onClick={() => navigate(-1)}>
                        <img src={BackIcon} alt="BackIcon" /></div>
                    {t('History')}
                </div>
            </div>

            <div className={style.profileHistoryBlock_content}>
                <div className={style.profileHistoryBlock_navigation}>
                    <div className={`${style.profileHistoryBlock_navigation_item} ${currentLocation == "/predict/profile/history/games" ? style.active : ""}`} onClick={() => navigate("/predict/profile/history/games")}>
                        {t("Games")}
                    </div>
                    <div className={`${style.profileHistoryBlock_navigation_item} ${currentLocation == "/predict/profile/history/balance" ? style.active : ""}`} onClick={() => navigate("/predict/profile/history/balance")}>
                        {t("Balance")}
                    </div>
                    <div className={`${style.profileHistoryBlock_navigation_item} ${currentLocation == "/predict/profile/history/usdt" ? style.active : ""}`} onClick={() => navigate("/predict/profile/history/usdt")}>
                        USDT
                    </div>
                </div>
                <Outlet />
            </div>
        </div>
    )
}
