export class CustomGameProcess {
    constructor(
        public game_process_id: number,
        public coef_down: number | null,
        public coef_up: number | null,
        public game_id: number,
        public user_in_game: boolean,
        public user_type_bet: boolean | null,
        public prize_pool: number | null,
        public percents_up: number | null,
        public percents_down: number | null,
        public prize_pool_down: number | null,
        public prize_pool_up: number | null,
        public prize_pool_usdt: number | null,
    ) { }
}

export function mapToCustomGameProcess(data: any) {
    return new CustomGameProcess(
        data.game_process_id,
        data.coef_down != null ? parseFloat(data.coef_down) : null,
        data.coef_up != null ? parseFloat(data.coef_up) : null,
        data.game_id,
        data.user_in_game,
        data.user_type_bet,
        data.prize_pool != null ? parseFloat(data.prize_pool) : null,
        data.percents_up != null ? parseFloat(data.percents_up) : null,
        data.percents_down != null ? parseFloat(data.percents_down) : null,
        data.prize_pool_down != null ? parseFloat(data.prize_pool_down) : null,
        data.prize_pool_up != null ? parseFloat(data.prize_pool_up) : null,
        data.prize_pool_usdt != null ? parseFloat(data.prize_pool_usdt) : null,
    )
}