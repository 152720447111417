import { useNavigate } from 'react-router-dom';
import style from './style.module.scss';
import { useContext, useMemo, useState } from 'react';
import GameContext from '../../../../Context/GameContext';
import CoinIcon from '../../../../Resources/Predict/images/coin.svg';
import СoinBrownIcon from '../../../../Resources/Predict/icons/coin_brown.svg';
import DownArrowDarkIcon from '../../../../Resources/Predict/icons/downArrow_dark.svg';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import TonIcon from '../../../../Resources/Predict/icons/ton.svg';
import { Currency } from '../../../../Providers/GameProvider';
import { useTranslation } from 'react-i18next';

export default function BalanceCard() {
    const { score, dao_balance, updateGame, balance_usdt, selectedCurrency } = useContext(GameContext);
    const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(2)), [score])

    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();

    function toggleMenu(e: any) {
        e.stopPropagation();

        setMenuOpen(!menuOpen);
    };

    function handleMenuItemClick(e: any, currency: Currency) {
        e.stopPropagation();

        updateGame({ selectedCurrency: currency })
        setMenuOpen(false);
    };

    function formatNumber(value: number): string {
        return Number.isInteger(value) ? value.toString() : value.toFixed(2);
    }

    function getCoinImage() {
        switch (selectedCurrency) {
            case Currency.Pix: return CoinIcon;
            case Currency.Dao: return СoinBrownIcon;
            case Currency.Ton: return TonIcon;
            case Currency.Usdt: return UsdtIcon;
        }
    }

    function getCoinValue() {
        switch (selectedCurrency) {
            case Currency.Pix: return predictBalance.toLocaleString("en");
            case Currency.Dao: return (+dao_balance.toFixed(2)).toLocaleString("en");
            case Currency.Ton: return 0;
            case Currency.Usdt: return formatNumber(+balance_usdt);
        }
    }

    return (
        <div className={style.header_balance}>
            <div className={style.header_balance__content} onClick={toggleMenu}>
                <img src={getCoinImage()} alt="СoinIcon" />
                <div className={style.header_balance_title}>
                    {getCoinValue()}
                </div>
                <img
                    src={DownArrowDarkIcon}
                    className={style.arrow_icon}
                // onClick={toggleMenu}
                />
            </div>
            <div>
                {
                    menuOpen && (
                        <div className={style.dropdown_menu}>
                            <div onClick={(e) => handleMenuItemClick(e, Currency.Pix)}>
                                <img src={CoinIcon} alt="СoinIcon" />
                                <span >
                                    {predictBalance.toLocaleString("en")}
                                </span>
                            </div>
                            <div onClick={(e) => handleMenuItemClick(e, Currency.Dao)}>
                                <img src={СoinBrownIcon} alt="СoinIcon" />
                                <span>
                                    {(+dao_balance.toFixed(2)).toLocaleString("en")}
                                </span>
                            </div>
                            <div onClick={(e) => handleMenuItemClick(e, Currency.Ton)}>
                                <img src={TonIcon} />
                                <span>Ton: 0</span>
                            </div>
                            <div onClick={(e) => handleMenuItemClick(e, Currency.Usdt)}>
                                <img src={UsdtIcon} />
                                <span>Usdt: {formatNumber(+balance_usdt)}</span>
                            </div>
                            <div onClick={() => navigate("/predict/profile/history/games")}>
                                <span>{t("History")}</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
