import { useTranslation } from 'react-i18next';
import { RoundedNumberInput } from '../../../../Components/Predict/inputs/roundedTextInput/roundedNumberInput'
import { RoundedTextInput } from '../../../../Components/Predict/inputs/roundedTextInput/roundedTextInput'
import './withdrawPageStyle.scss'
import { useContext, useState } from 'react';
import GameContext from '../../../../Context/GameContext';
import { RoundedSelect } from '../../../../Components/Predict/inputs/roundedSelect/roundedSelect';
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import BalanceCard from '../../predictions/balanceCard/balanceCard';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import { WithdrawProvider } from '../../../../Domain/Withdraw/withdrawProvider';
import PLoader from '../../../../Components/Predict/loader/Loader';
import Loader from '../../../../Components/Loader';

enum Network {
    TRC20 = 1
}

export default function WithdrawPage() {
    const { token, balance_usdt } = useContext(GameContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [selectedNetwork, setSelectedNetwork] = useState<Network | null>(Network.TRC20);
    const [withdrawAmount, setWithdrawAmount] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const networks = [Network.TRC20];

    function translatedName(network: Network | null) {
        if (network == null) return "";

        switch (network) {
            case Network.TRC20: return "TRC20";
        }
    }

    async function withdraw() {
        if (walletAddress == null || walletAddress.trim() == "") return toast.error(t("Specify wallet address"));
        if (selectedNetwork == null) return toast.error(t("Specify network"));
        if (withdrawAmount == null || withdrawAmount == 0) return toast.error(t("Specify amount"));

        setIsLoading(true);
        const result = await WithdrawProvider.withdraw(withdrawAmount, translatedName(selectedNetwork), walletAddress, token!);
        setIsLoading(false);
        if (result.message == "ok") {
            toast.success(t("Success"));
            setTimeout(() => navigate("/predict/profile/history/usdt"), 1_000)
        }
        else {
            return toast.error(result.message);
        }
    }

    return (
        <div className='withdraw-page-container'>
            {isLoading ? <PLoader />
                : <>
                    <div className='withdraw-page-container-header'>
                        <div className='withdraw-page-container-header-logo' onClick={() => navigate("/predict/predictions")}>
                            <img src={PixieLogo} alt="PixieLogo" />
                        </div>
                        <div className='withdraw-page-container-header-right'>
                            <BalanceCard />
                        </div>
                    </div>

                    <h1>{t("Withdraw")}</h1>
                    <div className='withdraw-page-container-item'>
                        <div className='withdraw-page-container-item-title'>
                            {t("Wallet address")}
                        </div>
                        <div className='withdraw-page-container-item-input'>
                            <RoundedTextInput
                                value={walletAddress}
                                placeholder={t("Wallet address")}
                                onChange={(address) => setWalletAddress(address)}
                            />
                        </div>
                    </div>
                    <div className='withdraw-page-container-item'>
                        <div className='withdraw-page-container-item-title'>
                            {t("Network")}
                        </div>
                        <div className='withdraw-page-container-item-input'>
                            <RoundedSelect<Network | null>
                                options={networks.map(network => ({ value: network }))}
                                getOptionLabel={translatedName}
                                placeholder='Network'
                                getOptionValue={option => option ?? ""}
                                onChange={network => setSelectedNetwork(network)}
                                value={selectedNetwork}
                            />
                        </div>
                    </div>
                    <div className='withdraw-page-container-item'>
                        <div className='withdraw-page-container-item-title'>
                            {t("Amount")}
                            <img src={UsdtIcon} />
                        </div>
                        <div className='withdraw-page-container-item-input'>
                            <RoundedNumberInput
                                value={withdrawAmount}
                                onChange={value => setWithdrawAmount(value)}
                                color='light'
                                minValue={0}
                                maxValue={balance_usdt}
                                placeholder={"0"}
                            />
                        </div>
                    </div>

                    <div className='withdraw-page-container-confirm-button' onClick={withdraw}>
                        {t("Withdraw")}
                    </div>
                </>
            }
            <Toaster />
        </div>
    )
}
