import { useContext, useEffect, useState } from 'react';
import { Card } from '../../../Components/Predict/card/card';
import CalendarIcon from '../../../Resources/Predict/icons/calendar.svg';
import CalendarDarkIcon from '../../../Resources/Predict/icons/calendar_dark.svg';
import TopUpIcon from '../../../Resources/Predict/icons/topUp.svg';
import TopUpYellowIcon from '../../../Resources/Predict/icons/topUpYellow.svg';
import TopUpDarkIcon from '../../../Resources/Predict/icons/topUp_dark.svg';
import downBalanceArrow_dark from '../../../Resources/Predict/icons/downBalanceArrow_dark.svg';
import downBalanceArrow_light from '../../../Resources/Predict/icons/downBalanceArrow_light.svg';
import style from './profileStyle.module.scss';
import { useTheme } from '../../../Context/Predict/themeContext';
import { UserBalanceHistory } from '../../../Domain/User/userBalanceHistory';
import { UserProvider } from '../../../Domain/User/userProvider';
import GameContext from '../../../Context/GameContext';
import PLoader from '../../../Components/Predict/loader/Loader';
import CoinIcon from '../../../Resources/Predict/icons/coin.svg';
import UsdtIcon from '../../../Resources/Predict/icons/USDT.svg';

interface ITransaction {
    source: string;
    date: Date;
    balance: number;
    amount: number;
}

export function ProfileBalanceHistoryBlock() {
    const { token } = useContext(GameContext);
    const [balanceHistory, setBalanceHistory] = useState<UserBalanceHistory[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { theme } = useTheme();

    useEffect(() => {
        loadGameHistory();
    }, [])

    async function loadGameHistory() {
        setIsLoaded(false);

        const balanceHistory = await UserProvider.getBalanceHistory(token!);
        setBalanceHistory(balanceHistory)
        setIsLoaded(true);
    }

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.profileBalancaHistoryBlock}>
            {
                balanceHistory.filter(item => item.coinType != "USDT").map((item, index) =>
                    <Card key={index} className={style.profileBalancaHistoryBlock_transaction}>
                        <div className={style.profileBalancaHistoryBlock_transactionLeft}>
                            {
                                item.type == true && item.comment == 'refund'
                                    ? <img src={TopUpYellowIcon} alt="topUpIcon" />
                                    : item.type
                                        ? <img src={theme == 'dark' ? TopUpDarkIcon : TopUpIcon} alt="topUpIcon" />
                                        : <img src={theme == 'dark' ? downBalanceArrow_dark : downBalanceArrow_light} alt="topUpIcon" />
                            }
                            <div className={style.profileBalancaHistoryBlock_transactionLeft_information}>
                                <div>{item.comment}</div>
                                <div className={style.profileBalancaHistoryBlock_transactionLeft_information_date}>
                                    <img src={theme == 'light' ? CalendarIcon : CalendarDarkIcon} alt="calendarIcon" />
                                    <span>{item.created_at.toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.profileBalancaHistoryBlock_transactionRight}>
                            <div className={style.profileBalancaHistoryBlock_transactionRight_amount}>

                                <img src={item.coinType == "USDT" ? UsdtIcon : CoinIcon} />
                                <span>{item.type ? "+" : "-"} {(+(+(item.price)).toFixed(2)).toLocaleString("en")}</span>
                            </div>
                        </div>
                    </Card>
                )
            }
        </div>
    )
}
