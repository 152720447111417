export class WithdrawProvider {

    public static async withdraw(amount: number, network: string, walletAddress: string, token: string) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/invoice/withdraw`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token!
            },
            body: JSON.stringify({
                "usdt": amount,
                "address": walletAddress,
                "network": network
            })
        })
            .then(response => response.json())
            .then(response => response);

        return response;
    }

}